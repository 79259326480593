import React from "react"
import { Form } from "antd"

const SignUpPage = () => {
  return (
    <div>
      sign up
    </div>
  )
}

export default SignUpPage
